import React from 'react';
import './App.css';
import './animations.css'; // Importiere die Animationen
import Navbar from './components/Navbar';
import Block from './components/Block';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import ScrollToTop from './components/ScrollToTop'; // Importiere ScrollToTop
import BackgroundImage from './img/ConceptArt2.png';
import Games from './components/Games';
import HiddenTruths from './components/HiddenTruths'; // Neue Komponente importieren
import ComingSoon from './components/ComingSoon'; // Neue Komponente importieren
import CookieBanner from './components/CookieBanner';
import NotFound from './components/NotFound';  // Importiere die 404-Seite

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop /> {/* Füge ScrollToTop hinzu */}
        <Navbar bgColor="#000" textColor="#fff" transparentWhenTop={false} />
        <Routes>
          <Route path="/" element={
            <>
              <Block bgColor="#000" tColor="#BDBFAE" bgImage={BackgroundImage} align="left" filter={true}>
                <div className="fade-in" style={{ position: 'relative', color: '#fff' }}>
                  <h1 style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Hidden Truths</h1>
                  <p style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Discover the secrets <br/> that lie beneath the surface.</p>
                  <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                    <Link to="/games" style={{ textDecoration: 'none' }}>
                      <button className="animated-button">Learn More</button>
                    </Link>
                  </div>
                </div>
              </Block>
              <Block bgColor="#818C88" tColor="#0D0D0B" align="center" filter={false}>
                <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto' }}>
                  <h2>About Absolute Chaos Studios</h2>
                  <p>
                    Absolute Chaos Studios is a dedicated game studio committed to creating unique and captivating gaming experiences.
                    Our mission is to tell stories that draw players into their worlds and keep them engaged. Join us on our journey to
                    bring unforgettable adventures to life.
                  </p>
                  <Link to="/studio" style={{ textDecoration: 'none' }}>
                    <button className="animated-button">Learn More About Us</button>
                  </Link>
                </div>
              </Block>
            </>
          } />
          <Route path="/games" element={<Games />} />
          <Route path="/games/hidden-truths" element={<HiddenTruths />} />
          <Route path="/games/coming-soon" element={<ComingSoon />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer bgColor="#0D0D0B" textColor="#BDBFAE" />
        <CookieBanner /> {/* Füge den CookieBanner hinzu */}
      </div>
    </Router>
  );
}

export default App;
