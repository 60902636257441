import React from 'react';
import styled from 'styled-components';

const BlockContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: ${props => {
    switch (props.$align) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      case 'left':
      default:
        return 'flex-start';
    }
  }};
  background-color: ${props => props.color || 'transparent'};
  background-image: ${props => props.$bgImage ? `url(${props.$bgImage})` : 'none'};
  background-size: cover;
  background-position: center;
  font-size: 24px;
  color: ${props => props.$textColor || '#BDBFAE'};
  transition: background-color 0.3s ease;
  padding: 20px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.$filter ? `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 1))` : 'none'};
    z-index: 0;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
`;

const Content = styled.div`
  max-width: 800px;
  text-align: ${props => props.$align || 'center'};
  margin-left: ${props => (props.$align === 'left' ? '5%' : '0')};
  margin-right: ${props => (props.$align === 'right' ? '5%' : '0')};
`;

const Block = ({ children, bgColor, bgImage, tColor, align, filter }) => {
  return (
    <BlockContainer color={bgColor} $bgImage={bgImage} $textColor={tColor} $align={align} $filter={filter}>
      <Content $align={align}>
        {children}
      </Content>
    </BlockContainer>
  );
};

export default Block;
