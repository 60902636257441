import React from 'react';

const Impressum = () => {
  return (
    <div style={{ paddingTop: '250px', paddingBottom: '200px', backgroundColor: '#000', color: '#fff', minHeight: '50px'}}>
      <h1>Impressum</h1>
      <p>Maurice Rostamitorab</p>
      <p>Leitenstorfferstraße 9a</p>
      <p>40597 Düsseldorf</p>
      <h2>Kontakt</h2>
      <p>Telefon:</p>
      <p>+49 176 40491302</p>
      <p>E-Mail:</p>
      <p>info@absolutechaosstudios.com</p>
    </div>
  );
};

export default Impressum;
