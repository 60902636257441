import React from 'react';
import styled from 'styled-components';
import 'boxicons';

const FooterContainer = styled.footer`
  background-color: #0D0D0B;
  color: #BDBFAE;
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const SocialMedia = styled.div`
  margin: 20px 0;
  a {
    margin: 0 10px;
    color: #BDBFAE;
    font-size: 2em;
    transition: color 0.3s;
    &:hover {
      color: #fff;
    }
  }

  box-icon {
    fill: #BDBFAE;
  }

  a:hover box-icon {
    fill: #fff;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialMedia>
        <a href="#"><box-icon type='logo' name='twitter' color="#BDBFAE"></box-icon></a>
        <a href="#"><box-icon type='logo' name='instagram' color="#BDBFAE"></box-icon></a>
        <a href="#"><box-icon type='logo' name='youtube' color="#BDBFAE"></box-icon></a>
      </SocialMedia>
      <p>&copy; 2024 Maurice Rostamitorab</p>
      <div>
        <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
      </div>
    </FooterContainer>
  );
};

export default Footer;
