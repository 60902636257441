import React from 'react';
import GameBlock from './GameBlock';
import HiddenTruthsImage from '../img/ConceptArt4.png';
import ComingSoonImage from '../img/ComingSoon.png';

const games = [
  { image: HiddenTruthsImage, title: 'Hidden Truths', link: '/games/hidden-truths' },
  { image: ComingSoonImage, title: 'Coming Soon', link: '/games/coming-soon' },
  { image: ComingSoonImage, title: 'Coming Soon', link: '/games/coming-soon' }
];

const Games = () => {
  return (
    <GameBlock bgColor="#000" tColor="#fff" games={games} />
  );
};

export default Games;
