import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

const Content = styled.div`
  max-width: 800px;
`;

const ComingSoon = () => {
  return (
    <Container>
      <Content>
        <h1>Coming Soon</h1>
        <p>
          This section is under development. Please stay tuned for exciting updates and new content.
        </p>
      </Content>
    </Container>
  );
};

export default ComingSoon;
