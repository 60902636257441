import React, { useState } from 'react';
import styled from 'styled-components';
import 'boxicons';

const BannerContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${props => (props.$visible ? 'block' : 'none')};
  z-index: 1000;
  min-width: 300px;
`;

const CookieIcon = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px; /* Größere Größe */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1001;

  &:hover {
    background-color: #444;
  }
`;

const Button = styled.button`
  background-color: #666;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #777;
  }
`;

const LanguageButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const LanguageButton = styled.button`
  background-color: #444;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: #555;
  }
`;

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [language, setLanguage] = useState('de');

  const handleAccept = () => {
    setAccepted(true);
    setVisible(false);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <>
      <CookieIcon onClick={() => setVisible(true)}>
        <box-icon name='cookie' color='#fff' size='lg'></box-icon>
      </CookieIcon>
      <BannerContainer $visible={visible}>
        <LanguageButtons>
          <LanguageButton onClick={() => handleLanguageChange('de')}>DE</LanguageButton>
          <LanguageButton onClick={() => handleLanguageChange('en')}>EN</LanguageButton>
        </LanguageButtons>
        {language === 'de' ? (
          <>
            <h3>Cookie Hinweis</h3>
            <p>Diese Website verwendet momentan keine Cookies. Dies ist nur eine Platzhalternachricht zu Demonstrationszwecken.</p>
          </>
        ) : (
          <>
            <h3>Cookie Notice</h3>
            <p>This website does not use cookies at the moment. This is just a placeholder message for demonstration purposes.</p>
          </>
        )}
        <Button onClick={handleAccept}>Accept</Button>
      </BannerContainer>
    </>
  );
};

export default CookieBanner;
