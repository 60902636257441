import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const GameBlockContainer = styled.div`
  background-color: ${props => props.$bgColor || 'transparent'};
  color: ${props => props.$tColor || '#BDBFAE'};
  padding: 20px;
  padding-top: 110px; /* Optimaler oberer Abstand */
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the start to leave space at the top */
`;

const GameGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1400px;
`;

const GameCardContainer = styled(Link)`
  flex: 0 1 45%;
  max-width: 45%;
  height: 400px;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover img {
    transform: scale(1.1);
  }

  &:hover div {
    transform: translateY(0);
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
  }
`;

const GameImage = styled.img`
  width: 100%;
  height: 70%;
  object-fit: cover;
  transition: transform 0.3s ease;
  z-index: 0;
`;

const GameTitle = styled.div`
  padding: 20px;
  font-size: 24px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  z-index: 2;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.7; /* Weniger auffällig */
  transform: translateY(0);

  ${GameCardContainer}:hover & {
    opacity: 1;
  }
`;

const GameCard = ({ image, title, link }) => {
  return (
    <GameCardContainer to={link}>
      <GameImage src={image} alt={title} />
      <GameTitle>{title}</GameTitle>
    </GameCardContainer>
  );
};

const GameBlock = ({ bgColor, tColor, games }) => {
  return (
    <GameBlockContainer $bgColor={bgColor} $tColor={tColor}>
      <GameGrid>
        {games.map((game, index) => (
          <GameCard key={index} image={game.image} title={game.title} link={game.link} />
        ))}
      </GameGrid>
    </GameBlockContainer>
  );
};

export default GameBlock;
