import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import 'boxicons';
import logo from '../img/default-monochrome-white.png';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.$shrink ? '5px 20px' : '10px 20px')};
  background-color: ${props => (props.$isTop && props.$transparentWhenTop ? 'transparent' : props.$bgColor)};
  color: ${props => props.$textColor || '#BDBFAE'};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: padding 0.3s ease, background-color 0.3s ease, color 0.3s ease;

  &:hover {
    padding: 10px 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled(Link)`
  width: ${props => (props.$shrink ? '150px' : '300px')}; // Passe die Größe entsprechend an
  height: auto;
  transition: width 0.3s ease;
  display: inline-block;

  img {
    width: 100%;
    height: auto;
  }
`;

const NavItemsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    width: 100%;
    display: ${props => (props.$isOpen ? 'block' : 'none')};
    background-color: ${props => (props.$isOpen ? props.$bgColor : 'transparent')}; /* Set background color only when menu is open */
  }
`;

const NavItems = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

const NavItem = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #BDBFAE;
  }

  @media (max-width: 1024px) {
    padding: 10px;
    text-align: center;
    width: 100%;
  }
`;

const MenuIcon = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
    position: absolute;
    right: 20px;
  }
`;

const Navbar = ({ bgColor, textColor, transparentWhenTop = true }) => {
  const [shrink, setShrink] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setShrink(true);
      setIsTop(false);
    } else {
      setShrink(false);
      setIsTop(true);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <NavbarContainer
      $shrink={shrink}
      $isTop={isTop}
      $isOpen={isOpen}
      $bgColor={bgColor}
      $textColor={textColor}
      $transparentWhenTop={transparentWhenTop}
    >
      <Logo to="/" $shrink={shrink}>
        <img src={logo} alt="Logo" />
      </Logo>
      <MenuIcon onClick={toggleMenu}>
        <box-icon name='menu' color={textColor || '#BDBFAE'}></box-icon>
      </MenuIcon>
      <NavItemsContainer $isOpen={isOpen} $bgColor={bgColor}>
        <NavItems>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/games">Games</NavItem> {/* Link zur Games-Seite */}
          <NavItem to="#news">News</NavItem>
          <NavItem to="#studio">Studio</NavItem>
          <NavItem to="#contact">Contact</NavItem>
        </NavItems>
      </NavItemsContainer>
    </NavbarContainer>
  );
};

export default Navbar;
