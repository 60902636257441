import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import 'boxicons';

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 40px auto;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.3s;
  opacity: ${props => (props.$controlsvisible ? 1 : 0)};
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;

const ProgressBar = styled.input`
  flex-grow: 1;
  margin: 0 10px;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #444;
  outline: none;
  cursor: pointer;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
  }
`;

const VolumeControl = styled.input`
  width: 100px;
  margin-left: 10px;
  appearance: none;
  height: 5px;
  background: #444;
  outline: none;
  cursor: pointer;
  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
  }
`;

const CustomVideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(1);
  const [previousVolume, setPreviousVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  const [mouseHidden, setMouseHidden] = useState(false);

  const togglePlay = () => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
  };

  const handleProgress = () => {
    const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    setProgress(progress);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value / 100) * videoRef.current.duration;
    videoRef.current.currentTime = seekTime;
    setProgress(e.target.value);
  };

  const handleVolumeChange = (e) => {
    const volume = e.target.value;
    videoRef.current.volume = volume;
    setVolume(volume);
    if (volume > 0) {
      setPreviousVolume(volume);
    }
  };

  const toggleMute = () => {
    if (volume > 0) {
      setPreviousVolume(volume);
      setVolume(0);
      videoRef.current.volume = 0;
    } else {
      setVolume(previousVolume);
      videoRef.current.volume = previousVolume;
    }
  };

  const getVolumeIcon = () => {
    if (volume < 0.05) return 'volume-mute';
    if (volume < 0.33) return 'volume';
    if (volume < 0.66) return 'volume-low';
    return 'volume-full';
  };

  const handleVideoEnd = () => {
    setPlaying(false);
    setProgress(0); // Reset progress bar to start
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
        containerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleMouseMove = () => {
    setControlsVisible(true);
    setMouseHidden(false);
    clearTimeout(containerRef.current.timeoutId);
    containerRef.current.timeoutId = setTimeout(() => {
      setControlsVisible(false);
      setMouseHidden(true);
    }, 3000);
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(container.timeoutId);
    };
  }, []);

  return (
    <VideoContainer ref={containerRef} style={{ cursor: mouseHidden ? 'none' : 'default' }}>
      <Video
        ref={videoRef}
        src={src}
        onClick={togglePlay}
        onDoubleClick={toggleFullscreen}
        onTimeUpdate={handleProgress}
        onEnded={handleVideoEnd}
        controls={false}
      />
      <Controls $controlsvisible={controlsVisible}>
        <Button onClick={togglePlay}>
          {playing ? <box-icon name='pause' color='#fff'></box-icon> : <box-icon name='play' color='#fff'></box-icon>}
        </Button>
        <ProgressBar type="range" min="0" max="100" value={progress} onChange={handleSeek} />
        <Button onClick={toggleMute}>
          <box-icon name={getVolumeIcon()} color='#fff'></box-icon>
        </Button>
        <VolumeControl type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
        <Button onClick={toggleFullscreen}>
          {isFullscreen ? <box-icon name='exit-fullscreen' color='#fff'></box-icon> : <box-icon name='fullscreen' color='#fff'></box-icon>}
        </Button>
      </Controls>
    </VideoContainer>
  );
};

export default CustomVideoPlayer;
