import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  background-color: #000;
  color: #fff;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.5em;
  margin-bottom: 40px;
`;

const BackButton = styled(Link)`
  padding: 10px 20px;
  background-color: #666;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2em;
  transition: background-color 0.3s;

  &:hover {
    background-color: #777;
  }
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      <Title>404 - Page Not Found</Title>
      <Description>Sorry, the page you are looking for does not exist.</Description>
      <BackButton to="/">Go Back Home</BackButton>
    </NotFoundContainer>
  );
};

export default NotFound;
