import React from 'react';
import styled from 'styled-components';
import HiddenTruthsImage1 from '../img/ConceptArt5.png';
import HiddenTruthsImage2 from '../img/ConceptArt6.png';
import HiddenTruthsImage3 from '../img/ConceptArt7.png';
import Video1 from '../vid/temporary_video.mp4';
import CustomVideoPlayer from './CustomVideoPlayer';

const Section = styled.section`
  padding: 60px 20px;
  text-align: center;
  background-color: ${props => props.$bgcolor || '#000'};
  color: ${props => props.$color || '#fff'};
`;

const HeroImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #BDBFAE;
`;

const Description = styled.p`
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto 40px;
  color: #fff;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto 40px;
  text-align: left;

  li {
    font-size: 1.1em;
    margin-bottom: 10px;
  }
`;

const Screenshots = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  img {
    width: 30%;
    height: auto;
    border-radius: 5px;
  }
`;

const Team = styled.div`
  max-width: 800px;
  margin: 0 auto 40px;
`;

const TeamMember = styled.div`
  margin-bottom: 20px;
`;

const HiddenTruths = () => {
  return (
    <>
      <Section $bgcolor="#000" $color="#fff">
        <HeroImage src={HiddenTruthsImage1} alt="Hidden Truths" />
        <Title>Hidden Truths</Title>
        <Subtitle>Discover the secrets that lie beneath the surface</Subtitle>
        <Description>
          Hidden Truths is an adventure RPG that plunges players into the shoes of a detective embroiled in a world filled with mystery and conspiracy. 
          Players navigate a tension-filled narrative where their choices influence the investigation's direction and the fate of its characters.
        </Description>
      </Section>

      <Section $bgcolor="#1a1a1a" $color="#BDBFAE">
        <h2>Key Features</h2>
        <FeaturesList>
          <li>Deep Narrative: A complex conspiracy involving government infiltration and high-stakes crime.</li>
          <li>Dynamic Decision System: Choices impact the storyline and relationships with other characters.</li>
          <li>Diverse Investigation Tactics: Techniques such as interrogations, evidence analysis, and direct observations.</li>
          <li>Interactive World: A vibrant world where every interaction and location contributes to the unfolding story.</li>
          <li>Character Progression: Characters evolve based on player decisions, offering a personalized gameplay experience.</li>
        </FeaturesList>
      </Section>

      <Section $bgcolor="#000" $color="#fff">
        <h2>Concept Art</h2>
        <Screenshots>
          <img src={HiddenTruthsImage1} alt="Screenshot 1" />
          <img src={HiddenTruthsImage2} alt="Screenshot 2" />
          <img src={HiddenTruthsImage3} alt="Screenshot 3" />
        </Screenshots>
      </Section>

      <Section $bgcolor="#1a1a1a" $color="#BDBFAE">
        <h2>Gameplay Trailer (Coming Soon)</h2>
        <CustomVideoPlayer src={Video1} />
        
      </Section>

      <Section $bgcolor="#000" $color="#fff">
        <h2>Meet the Team</h2>
        <Team>
          <TeamMember>
            <h3>Maurice Rostamitorab</h3>
            <p>Lead Developer</p>
          </TeamMember>
        </Team>
      </Section>
    </>
  );
};

export default HiddenTruths;
